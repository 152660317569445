import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';

const Navigation = (props) => {

  const data = useStaticQuery(graphql`
    query NavigationQuery {
      navLogo: file(absolutePath: { regex: "/pratiksoslar.png/" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div id="main_nav" className="container">
      <Navbar color="white" light expand="md">
        <Link to="/" className="navbar-brand"><Image fluid={data.navLogo.childImageSharp.fluid} imgStyle={{objectFit: "contain",objectPosition: "50% 50%",}} style={{width: "30px", marginRight: "10px", display: "inline-block"}} /> Pratik Soslar </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link to="/" className="nav-link">Anasayfa</Link>
            </NavItem>
            <NavItem>
              <Link to="/recipes" className="nav-link">Soslar</Link>
            </NavItem>
            <NavItem>
              <Link to="/tags" className="nav-link">Kategoriler</Link>
            </NavItem>
            <NavItem>
              <Link to="/about" className="nav-link">Hakkımızda</Link>
            </NavItem>
            <NavItem>
              <Link to="/privacy" className="nav-link">Gizlilik Sözleşmesi</Link>
            </NavItem>
          </Nav>
          <a href={"https://instagram.com/pratiksoslar"} rel="noreferrer" target="_blank"><img src={require('../../content/assets/instagram_pratik_soslar.png')} alt={"instagram pratiksoslar"}/></a>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;
